import { doc, onSnapshot } from "firebase/firestore";
import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { FirebaseContext } from "../components/Firebase";
import LoginFirst from "../components/loginfirst";
import Seo from "../components/seo";
import { ContentBg, ContentBox } from "../components/ui";

const ProfilePic = styled.img`
  float: right;
  width: 80px;
  box-shadow: 2px 2px 2px #ccc;
`;

const WorldCup2022 = () => {
  const { firebase, user } = useContext(FirebaseContext);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (!firebase || !user) {
      navigate("/");
    } else {
      const unsubscribe = onSnapshot(
        doc(firebase.db, "publicProfiles", user.email),
        doc => {
          setProfile(doc.data());
        }
      );
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, []);

  return (
    <ContentBg src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png">
      <ContentBox>
        <Seo title="World Cup Poule 2022" />
        <h1>
          <FormattedMessage id="your_profile" />
        </h1>

        {profile ? (
          <>
            {profile.photoURL && (
              <ProfilePic
                src={profile.photoURL}
                alt={profile.name}
                referrerPolicy="no-referrer"
              />
            )}
            <p>
              <strong>
                <FormattedMessage id="name" />:{" "}
              </strong>
              {profile.name}
            </p>
            <p>
              <strong>
                <FormattedMessage id="email" />:{" "}
              </strong>
              {profile.email}
            </p>
            {profile.percentagePredicted && (
              <>
                <hr />
                <h3>World Cup Poule 2022</h3>
                <p>
                  <strong>
                    <FormattedMessage id="wc2022_percentage_done" />
                    <Link to="/worldcup2022/schedule">
                      {" "}
                      {profile.percentagePredicted || 0}%
                    </Link>
                  </strong>
                </p>
                <p>
                  <strong>
                    <FormattedMessage id="wc2022_points" />:{" "}
                  </strong>
                  <span style={{ fontFamily: "Courier" }}>
                    {profile.totalPoints || 0}
                  </span>{" "}
                  |{" "}
                  <Link to="/worldcup2022/standings">
                    <FormattedMessage id="wc2022_standings" />
                  </Link>{" "}
                  |{" "}
                  <Link to="/worldcup2022/schedule">
                    <FormattedMessage id="wc2022_predictions" />
                  </Link>
                </p>
                {user &&
                  user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID && (
                    <Link to="/worldcup2022/admin">Admin</Link>
                  )}
              </>
            )}
          </>
        ) : (
          <LoginFirst />
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default WorldCup2022;
